import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';
import DataElement from '../../../presenters/DataElement';
import DataElementGroup from '../../../presenters/DataElementGroup';
import DataElementContainer from '../../../presenters/DataElementContainer';
import Tool from '../../../models/Tool';

class DialogAdd extends DefaultDialog {

  renderContents() {
    const { tool, mapData, job } = this.props;
    const { options } = this.state;

    // const regionType = 'Phigaro';
    // const regionType = 'misc_feature';
    // Phigoaro types changed in tool version 1.1.0
    // Added 2025-02-24: We could remove this in a year or so
    const versionLessThan110 = Tool.compareVersions('1.1.0', job.toolVersion) === 1;
    // const regionType = tool.versionLessThan('1.1.0') ? 'Phigaro' : 'misc_feature';
    const regionType = versionLessThan110 ? 'Phigaro' : 'misc_feature';
    console.log(tool)
    console.log(this.props.job)
    console.log(regionType)

    const features = mapData.features || [];
    const counts = {
      prophage: features.filter( f => f.type === regionType).length,
      genes:    features.filter( f => f.type !== regionType).length,
    };

    let legendFeatures = [...features]
    if (!options.add_prophage) {
      legendFeatures = legendFeatures.filter( f => f.type !== regionType);
    }
    if (!options.add_genes) {
      legendFeatures = legendFeatures.filter( f => f.type === regionType);
    }

    return (
      <div>
        {this.renderHeader()}

        <DataElementContainer label='Features to Add' maxHeight='130px'>
          <DataElementGroup>
            {this.renderInput('add_prophage', {help: `Count: ${counts.prophage}`, align: 'center'})}
            {this.renderInput('add_genes', {help: `Count: ${counts.genes}`, align: 'center'})}
          </DataElementGroup>
          <div style={{textAlign: 'center', width: '100%', fontSize: '0.65rem'}} className='help-text'>Regions will add one feature for each prophage. Genes will add a feature for each gene found within the prophage region.</div>

        </DataElementContainer>

        {this.renderInput('track')}
        {this.renderInput('legend', {featureData: legendFeatures})}

        {this.renderTips()}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}
          // {this.renderInput('add_crisprs', {width: '100px'})}
          // {this.renderInput('add_cas_clusters', {width: '100px'})}
          // {this.renderInput('add_cas_genes', {width: '100px'})}

export default DialogAdd;

