import React from 'react'
import ReactDOM from 'react-dom'
import './ProjectTable.css';
import '../proksee-viewer/support/CommonStyles.css';
import PropTypes from 'prop-types'
import DataElement from '../proksee-viewer/presenters/DataElement';
import DataElementGroup from '../proksee-viewer/presenters/DataElementGroup';
import TextElement from '../proksee-viewer/presenters/TextElement';
import ImageButton from '../proksee-viewer/presenters/ImageButton';
import ServerAPI from '../proksee-viewer/models/ServerAPI';
import { VirtualTable, Column } from '../proksee-viewer/presenters/VirtualTable';
import ProjectsDeleteDialog from './dialogs/ProjectsDeleteDialog';
import * as helpers from "../proksee-viewer/support/Helpers";
import * as d3 from "d3";

class ProjectTable extends React.Component {

  static propTypes = {
    projects:        PropTypes.array,
    title:           PropTypes.string,
    user:            PropTypes.object,
    sessionProjects: PropTypes.bool,
  }

  static defaultProps = {
    projects: [],
    title: 'Projects',
  }

  constructor(props) {
    super(props);
    this.state = {
      virtualTableRef: React.createRef(),
      projects: [],
      bulkClaimIDs: [],
      bulkDeleteIDs: [],
    }
    this.nameCellRenderer = this.nameCellRenderer.bind(this);
    this.infoBoxRenderer = this.infoBoxRenderer.bind(this);
    this.dateCreatedCellRenderer = this.dateCreatedCellRenderer.bind(this);
    this.dateUpdatedCellRenderer = this.dateUpdatedCellRenderer.bind(this);
  }

  nameCellRenderer({ rowData: project }) {
    const name = project.name || 'Untitled';
    //  <div className='column-value'>
    //    <div className='column-value-main'>
    //      <a onClick={(e) => e.stopPropagation() } href={`/projects/${project.uuid}`}>{name}</a>
    //    </div>
    //    <div className='column-value-sub monospace'>{project.uuid}</div>
    //  </div>
    return (
      <a className='btn-project' onClick={(e) => e.stopPropagation() } href={`/projects/${project.uuid}`}>
        <div className='column-value'>
          <div className='column-value-main'>
            {name}
          </div>
          <div className='column-value-sub monospace'>{project.uuid}</div>
        </div>
      </a>
    )
  }

  dateRenderer(date) {
    const dt = helpers.dateTime(date);
    // console.log(date)
    // console.log(dt.friendlyDateTime)
    return (
      <div className='column-value'>
        <div className='column-value-main'>{dt.friendlyDate}</div>
        <div className='column-value-sub'>{dt.time}</div>
      </div>
    )
  }

  dateCreatedCellRenderer({ rowData: project }) {
    return this.dateRenderer(project.createdAt);
  }

  dateUpdatedCellRenderer({ rowData: project }) {
    return this.dateRenderer(project.updatedAt);
  }

  sizeCellRenderer({ rowData: project }) {
    return (project.size ? helpers.formatBytes(project.size) : '0 B');
  }

  claimCellRenderer({ rowData: project }) {
    // return <a onClick={(e) => e.stopPropagation() } href={`/projects/${project.uuid}/claim`}><strong>Claim</strong></a>;
    return (
      <a className='btn-project' style={{textAlign: 'center'}} onClick={(e) => e.stopPropagation() } href={`/projects/${project.uuid}/claim`}>
        <strong>Claim</strong>
      </a>
    )
  }

  onDeleteProjects(ids) {
    this.setState({bulkDeleteIDs: ids});
  }

  infoBoxRenderer(project) {
    // console.log(project)
    const daysLeftElement = project.daysRemaining && (
      <DataElement label='Days Left (Before Deletion)'>
        <TextElement>{project.daysRemaining}</TextElement>
      </DataElement>
    );
    return (
      <div>
        <DataElement label='Name'>
          <TextElement>{project.name}</TextElement>
        </DataElement>
        <DataElement label='Map ID'>
          <TextElement>{project.uuid}</TextElement>
        </DataElement>
        <DataElementGroup>
          <DataElement label='Created'>
            <TextElement>{helpers.friendlyDateTime(project.createdAt)}</TextElement>
          </DataElement>
          <DataElement label='Updated (Last Save Date)'>
            <TextElement>{helpers.friendlyDateTime(project.updatedAt)}</TextElement>
          </DataElement>
        </DataElementGroup>
        <DataElementGroup>
          <DataElement label='Project Size'>
            <TextElement>{helpers.formatBytes(project.size)}</TextElement>
          </DataElement>
          {daysLeftElement}
        </DataElementGroup>
        <DataElementGroup>
          <DataElement label='Source'>
            <TextElement>{project.source}</TextElement>
          </DataElement>
        </DataElementGroup>
        <DataElementGroup>
          <DataElement label='Tools Run'>
            <TextElement>{project.toolPublicIds}</TextElement>
          </DataElement>
        </DataElementGroup>
        <div className='action-buttons'>
          <ImageButton imageName='delete' text='Delete Project' width={120}
            title='Delete Project' onClick={ () => this.onDeleteProjects([project.uuid]) } />
        </div>
      </div>
    )
  }

  renderDeleteDialog() {
    const { bulkDeleteIDs, virtualTableRef } = this.state;
    if (bulkDeleteIDs.length > 0) {
      return (
        <ProjectsDeleteDialog
          projectIDs={bulkDeleteIDs}
          projectTableRef={virtualTableRef}
          onClose={({action}) => {
            if (action === 'ok') {
              const Server = new ServerAPI();
              Server.deleteProjects(bulkDeleteIDs)
              .then( response => {
                if (response.ok) {
                  this.setState({bulkDeleteIDs: []});
                  location.reload();
                }
              });
            } else {
              this.setState({bulkDeleteIDs: []});
            }
          }}
        />
      );
    }
  }

  render() {
    const { projects, title, sessionProjects, user } = this.props;
    // const { projects } = this.state;
    const theme = 'light';
    // console.log(user)
    // console.log(projects)

    const claimColumn = (sessionProjects && user) && (
      <Column
        width={60}
        className='column-right'
        label=''
        dataKey='claim'
        search='nosearch'
        cellRenderer={this.claimCellRenderer}
      />
    );

    const daysRemainingColumn = (sessionProjects) && (
      <Column
        width={80}
        className='column-center'
        label='Days Left'
        dataKey='daysRemaining'
        search='number'
      />
    );

    return (
      <div className='ProjectTable'>
        <VirtualTable
          headerHeight={20}
          rowHeight={36}
          infoHeight={380}
          data={projects}
          dataName={title}
          idKey='uuid'
          infoRenderer={this.infoBoxRenderer}
          selectColumn={true}
          ref={this.state.virtualTableRef}
          deleteAction={(ids)=>{this.onDeleteProjects(ids)}}
        >
          <Column
            label='ID'
            dataKey='uuid'
            hidden={true}
          />
          <Column
            label='Source'
            dataKey='source'
            hidden={true}
          />
          <Column
            label='Name'
            dataKey='name'
            width={140}
            flexGrow={1}
            cellRenderer={this.nameCellRenderer}
          />
          <Column
            label='Date Created'
            className='narrow-hide'
            headerClassName='narrow-hide'
            dataKey='created_at'
            width={60}
            flexGrow={1}
            cellRenderer={this.dateCreatedCellRenderer}
          />
          <Column
            label='Last Save Date'
            dataKey='updated_at'
            width={70}
            flexGrow={1}
            cellRenderer={this.dateUpdatedCellRenderer}
          />
          {daysRemainingColumn}
          <Column
            width={70}
            className='column-right'
            headerStyle={{textAlign: 'right'}}
            label='Size'
            dataKey='size'
            search='number'
            cellRenderer={this.sizeCellRenderer}
          />
          <Column
            label='Tools'
            dataKey='toolPublicIds'
            hidden={true}
          />
          {claimColumn}
        </VirtualTable>
        {this.renderDeleteDialog()}
      </div>
    )
  }

}
export default ProjectTable;

