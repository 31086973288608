import Tool from '../../models/Tool';

export default function add({tool, inputs, mapData, job}) {

  // Filter mapData based on DialogAdd inputs

  // const regionType = 'misc_feature';
  // const regionType = 'Phigaro';
  // Phigoaro types changed in tool version 1.1.0
  // Added 2025-02-24: We could remove this in a year or so
  const versionLessThan110 = Tool.compareVersions('1.1.0', job.toolVersion) === 1;
  // const regionType = tool.versionLessThan('1.1.0') ? 'Phigaro' : 'misc_feature';
  const regionType = versionLessThan110 ? 'Phigaro' : 'misc_feature';
  // console.log(job)

  if (!inputs.add_prophage) {
    mapData.features = mapData.features.filter( f => f.type !== regionType);
  }
  if (!inputs.add_genes) {
    // Genes will have type of "Phigoro - TYPE"
    mapData.features = mapData.features.filter( f => f.type === regionType);
  }

  return { ok: true, mapData };
}

