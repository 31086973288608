import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';

class DialogAdd extends DefaultDialog {

  renderContents() {
    const { tool, mapData } = this.props;
    const features = mapData.features || [];
        // {this.renderInputsForTarget('DialogAdd')}

    return (
      <div>
        {this.renderHeader()}
        {this.renderInput('track')}
        {this.renderInput('legend', {featureData: features})}
        {this.renderTips()}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}

export default DialogAdd;
