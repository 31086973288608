import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';
import DataElementGroup from '../../../presenters/DataElementGroup';
import DataElementContainer from '../../../presenters/DataElementContainer';
import TextElement from '../../../presenters/TextElement';
import ImageButton from '../../../presenters/ImageButton';
// import Button from '../../../presenters/Button';
import Switch from '../../../presenters/Switch';
import Toast from '../../../presenters/Toast';
// import * as helpers from '../../../support/Helpers';
import './DialogAdd.css';

// TODO:
// - New Start from settings: outside, inside, backbone (use 1,2,3 and -1,-2,-3 for ring/lane names)

// FUTURE:
// - Add ability in track list caption, to set it as a static caption (ie not dynamic)

// NOTES:
// - This tool inegrates with the CGView.js plugin CaptionTrackList
// - CaptionTrackList is a simple plugin that displays a live list of tracks in a caption.
// - Plugin Options:
//   - startFrom: 'outside', 'inside', 'backbone'
//   - separator: 'return', 'semicolon', 'comma'
//   - collapseTracks: true, false

class DialogAdd extends DefaultDialog {

  get pluginID() { return 'pluginCaptionTrackList'; }

  componentDidMount() {
    const caption = this.makeCaption()
    this.setState({mapData: {...this.state.mapData, captions: [caption]}});
      const trackListCaption = this.getTrackListCaption();
      if (trackListCaption) {
        trackListCaption.updatePluginOptions(this.pluginID, this.state.options);
      }

  }

  // Override onChange to add listing text as caption to mapData
  onChange({attribute, value}) {
    super.onChange({attribute, value}, () => {
      console.log("CHANGE")
      const caption = this.makeCaption()
      this.setState({mapData: {...this.state.mapData, captions: [caption]}});

      // Check if a track list caption already exists and if so update it
      const trackListCaption = this.getTrackListCaption();
      if (trackListCaption) {
        console.log(this.state.options)
        this.saveDefaultSettings('ok');
        trackListCaption.updatePluginOptions(this.pluginID, this.state.options);
      }

    });
  }

  // Dialog Overrides
  propOkText() {
    return this.getTrackListCaption() ? '' : 'OK';
  }
  propOkButtonProps() {
    return this.getTrackListCaption() ? { style: { display: 'none' } } : {};
  }
  propCancelText() {
    return this.getTrackListCaption() ? 'Done' : 'Cancel';
  }
  propCancelButtonProps() {
    return this.getTrackListCaption() ? { style: { color: '#fff', backgroundColor: '#1890ff', borderColor: '#1890ff' } } : {};
  }
  renderDescription() {
    return this.getTrackListCaption() ? <div>
      <div className='flex-center'><strong>Track List Caption already exists</strong></div>
      <div className='flex-center' style={{padding: '5px 0 3px'}}><ImageButton imageName='edit' text='Edit Caption' width={120}
          title='Open Caption' onClick={ () => this.onOpenCaption() } /></div>
    </div> : <div>{this.component?.description}</div>
  }


  // Click the open caption button
  onOpenCaption() {
    const caption = this.getTrackListCaption();
    if (caption) {
      const sidebar = this.context.sidebarRef;
      const pane = sidebar.selectPane('captions');
      pane.openInfoFor(caption.cgvID);
      // FIXME: HACK to close the dialog
      const closeButton = document.querySelector('.ant-modal-close');
      if (closeButton) { closeButton.click(); }
    }

  }

  // Default Caption Settings
  // Use map background as caption background
  // Use ruler color as caption font color
  makeCaption() {
    const list = this.getListing();
    return {
      name: list,
      position: 'top-left',
      font: 'sans-serif,plain,12',
      fontColor: this.cgv?.ruler?.color?.rgbaString || 'rgb(100,100,100)',
      backgroundColor: this.cgv?.settings?.backgroundColor?.rgbaString || 'rgba(255,255,255,0.5)',
      pluginOptions: {
        [this.pluginID]: this.state.options
      }
    }
  }

  copyText() {
    const names = this.getListing();
    navigator.clipboard.writeText(names);
    Toast.create('Track List Copied to the Clipboard', 1500)
  }

  getListing() {
    const isLinear = this.isLinear();
    const tracks = cgv.tracks().filter( (t) => t.visible );
    return this.cgv.plugins?.get(this.pluginID)?.getListingText?.(tracks, isLinear, this.state.options) || 'Plugin Error';
  }

  isLinear() {
    const {options} = this.state;
    const format = options.map_format || this.cgv.format;
    return (format === 'linear');
  }

  // Override resetOptions (but use super) to update caption plugin options
  resetOptions() {
    const options = super.resetOptions();
    // Check if a track list caption already exists and if so update it with reset options
    const trackListCaption = this.getTrackListCaption();
    if (trackListCaption) {
      console.log("STATE", this.state.options)
      console.log("RETURN", options)
      trackListCaption.updatePluginOptions(this.pluginID, options);
    }
  }

  renderListing() {
    const names = this.getListing();
    const label = this.isLinear() ? 'Lane' : 'Ring'; 
    return (
      <div>
        <div className='track-listing-header'>
          <div className='label'>Track List by {label}</div>
          <ImageButton className='copy-track-listing' width="130px" size='large'
            onClick={() => this.copyText()}
            imageName='copy' text='Copy Track List' />
        </div>
        <TextElement className='track-listing-text'>
          {names}
        </TextElement>
      </div>
    );
  }

  // Returns first caption with CaptionTrackList plugin
  // Or undefined if not found
  getTrackListCaption() {
      const captions = this.cgv.captions();
      return  captions.find( (c) => c.hasPlugin(this.pluginID));
  }

  renderCaptionPresent() {
    const trackListCaption = this.getTrackListCaption();
    if (trackListCaption) {
      return (
        <div>
          <div className='dialog-header flex-center'>Edit Track List Settings Below</div>
        </div>
      )
    }
  }

  renderCaptionHidden() {
    const caption = this.getTrackListCaption();
    if (caption && !caption.visible) {
      return (
        <div className='dialog-header'>
          <Switch
            value={caption.visible}
            onString='Caption Visible'
            offString='Caption Hidden'
            onChange={(value) => caption.update({visible: value})}
          />
        </div>
      );
    }
  }

  onDeleteCaption(cgvID) {
    const cgv = this.context.cgv;
    const caption = cgv.objects(cgvID);
    caption.remove();
    this.setState((prevState) => ({
      resetKey: prevState.resetKey + 1,
    }));
  }

  renderFooter() {
    const caption = this.getTrackListCaption()
    if (caption) {
      return <div className='btn-delete-track-list-caption'><ImageButton imageName='delete' text='Delete Caption' width={135}
          title='Delete Caption' onClick={ () => this.onDeleteCaption(caption.cgvID) } /></div>
    } else  {
      return this.renderTips(<span>Clicking <strong>OK</strong> will add the above list as a caption to the map. Captions do not wrap text, so using <strong>Return</strong> as the separator is recommended.</span>)
    }
  }

  renderContents() {
    // const { options } = this.state;
    // const isLinear = this.cgv.format === 'linear';
    // const startOutsideProps = this.isLinear() ? {onString: 'Top', offString: 'Bottom'} : {};
    // const collapseLabel = this.isLinear() ? 'Collapse Lanes' : 'Collapse Rings';

    return (
      <div>
        {this.renderHeader()}
        <div>
          {this.renderCaptionPresent()}
          {this.renderCaptionHidden()}
        </div>
        <DataElementContainer maxHeight='200' label='List Options'>
          <DataElementGroup>
            {this.renderInput('startFrom')}
            {/* {this.renderInput('start_from', startOutsideProps)} */}
            {this.renderInput('separator')}
          </DataElementGroup>
          <DataElementGroup>
            {this.renderInput('collapseTracks')}
            {/* {this.renderInput('collapse_tracks', {label: collapseLabel})} */}
            {/* {this.renderInput('separator')} */}
            {/* {this.renderInput('map_format', {defaultValue: isLinear ? 'linear' : 'circular'})} */}
          </DataElementGroup>
        </DataElementContainer>
        {this.renderListing()}
        {this.renderFooter()}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}

export default DialogAdd;



// OLD CODE

  // getSeparator() {
  //   const defaultSeparator = this.props.tool.inputs.get('separator').default;
  //   const separatorname = this.state.options.separator || defaultSeparator;

  //   const separatorMap = {
  //     return: '\n',
  //     semicolon: '; ',
  //     comma: ', ',
  //   }

  //   return separatorMap[separatorname];
  // }

  // Slots is an array of strings that decribes specific slots
  // Returns a empty string or a string cotainin each slot
  // displaySlots(slots) {
  //   let displayText = '';
  //   if(slots === undefined) return displayText;

  //   slots = (Array.isArray(slots)) ? slots : [slots];
  //   if (slots.length > 0 && slots[0] !== undefined) {
  //     displayText += ` (${slots.join(',')})`;
  //   }

  //   return displayText;
  // }


  // getListingOLD() {
  //   const startOutside = this.state.options.start_outside;
  //   const separator = this.getSeparator();
  //   const isLinear = this.isLinear();
  //   const label = isLinear ? 'lane' : 'ring'; 
  //   const direction = isLinear ? (startOutside ? 'top' : 'bottom') : (startOutside ? 'outermost' : 'innermost');
  //   let text = `Starting from the ${direction} ${label}:`;
  //   text += (separator === '\n') ? "\n" : " ";
  //   const tracks = cgv.tracks().filter( (t) => t.visible );

  //   // Array of objects with track and slot properties
  //   // slot is one of: undefined, +, -, -3, -2, -1, +1, +2, +3
  //   // Tracks (by default) will start from the outside
  //   const listing = [{track: {name: 'Backbone (Contigs)', backbone: true}}];
  //   for (const track of tracks) {
  //     // position: 'both', 'inside', 'outside'
  //     // separateFeaturesBy: 'readingFrame', 'strand', 'none'
  //     if (track.separateFeaturesBy === 'none' || track.type === 'plot') {
  //       if (track.position === 'inside') {
  //         listing.push({track})
  //       } else {
  //         listing.unshift({track});
  //       }
  //     } else if (track.separateFeaturesBy === 'strand') {
  //       if (track.position === 'inside') {
  //         ['+', '-'].forEach( (s) => { listing.push({track, slot: s}) });
  //       } else if (track.position === 'outside') {
  //         ['-', '+'].forEach( (s) => { listing.unshift({track, slot: s}) });
  //       } else {
  //         listing.unshift({track, slot: '+'});
  //         listing.push({track, slot: '-'})
  //       }
  //     } else if (track.separateFeaturesBy === 'readingFrame') {
  //       if (track.position === 'inside') {
  //         ['+3', '+2', '+1', '-1', '-2', '-3'].forEach( (s) => { listing.push({track, slot: s}) });
  //       } else if (track.position === 'outside') {
  //         ['-3', '-2', '-1', '+1', '+2', '+3'].forEach( (s) => { listing.unshift({track, slot: s}) });
  //       } else {
  //         ['+1', '+2', '+3'].forEach( (s) => { listing.unshift({track, slot: s}) });
  //         ['-1', '-2', '-3'].forEach( (s) => { listing.push({track, slot: s}) });
  //       }
  //     } else if (['type', 'legend'].includes(track.separateFeaturesBy)) {
  //       const features = track.featuresBy(track.separateFeaturesBy);
  //       const typesOrLegends = Object.keys(features);
  //       // Sort by number of features
  //       typesOrLegends.sort((a, b) => features[b].length - features[a].length);
  //       if (track.position === 'inside') {
  //         typesOrLegends.forEach( (s) => { listing.push({track, slot: s}) });
  //       } else {
  //         // Outside
  //         typesOrLegends.forEach( (s) => { listing.unshift({track, slot: s}) });
  //       }
  //     }
  //   }

  //   if (!startOutside) {
  //     listing.reverse();
  //   }

  //   let entries = [];
  //   let slots = [];
  //   if (this.state.options.collapse_tracks) {
  //     for (let i=0, len=listing.length; i < len; i++) {
  //       const track = listing[i].track;
  //       const s = listing[i].slot;
  //       const next = listing[i+1];
  //       slots.push(s);
  //       if (next && next.track === track) {
  //         continue;
  //       }
  //       entries.push(`${this.displayLabel(i, slots, track.backbone)}${track.name}${this.displaySlots(slots)}`);
  //       slots = [];
  //     }
  //   } else {
  //     entries = listing.map( (t, i) => `${this.displayLabel(i, slots, t.track.backbone)}${t.track.name}${this.displaySlots(t.slot)}` );
  //   }
  //   return text + entries.join(`${separator}`);
  // }

  // displayLabel(index, slots=[], backbone=false) {
  //   if (backbone) return '';
  //   slots = (Array.isArray(slots)) ? slots : [slots];
  //   let label = this.isLinear() ? 'Lane' : 'Ring';
  //   if (slots.length > 1) { label += 's'; }

  //   let numbers = '';
  //   if (slots.length <= 1) {
  //     return `${label} ${index+1}: `;
  //   } else if (slots.length === 2) {
  //     return `${label} ${index},${index+1}: `;
  //   } else {
  //     return `${label} ${index+2 - slots.length}-${index+1}: `;
  //   }
  // }