import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CGViewContext } from '../app/CGViewContext';
import ServerAPI from '../models/ServerAPI';
import Toast from '../presenters/Toast';
import Message from '../presenters/Message';
import ImageButton from '../presenters/ImageButton';
import Tools from '../models/Tools';
// import * as helpers from '../support/Helpers';

// Connected
import { connect } from 'react-redux';

class AddJobResultsButton extends Component {

  static propTypes = {
    id: PropTypes.number,
    job: PropTypes.object,
    onClose: PropTypes.func,
    type:  PropTypes.oneOf(['plain', 'button']),
    title: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }
  static defaultProps = {
    onClose: () => {},
    type:    'button',
    title: '',
  }

  constructor(props) {
    super(props);
    this.state = {
      openDialog: undefined,
      mapData: undefined,
    }
    this.onAddToMapClosed = this.onAddToMapClosed.bind(this);
  }

  fetchMapData() {
    const { job } = this.props;
    if (!job) return;
    Message.open({content:'Getting Map Results...'});

    const tool = Tools.get(job.tool.publicID);
    const Server = new ServerAPI();
    const url = Server.jobResults(job);
    Server.get(url)
    .then( response => {
      if (response.ok) {
        this.setState({openDialog: true, mapData: response.json});
      } else {
        Toast.create('No map data is available.', 3000)
        // FIXME: This may go in the close section
        this.setState({mapData: undefined});
      }
      Message.close();
    })
  }

  onAddClicked() {
    this.fetchMapData();
  }

  // Called when the job add dialog is closed
  onAddToMapClosed({action, options: inputs, dialog, mapData} = {}) {
    const { job, onClose } = this.props;
    const jobID = job.id
    if (action === 'cancel') {
      this.setState({openDialog: false, mapData: undefined});
      onClose();
    } else if (action === 'ok') {
      const tool = Tools.get(job.tool.publicID);
      const jobSourceID = job.cgviewSourceId && `${job.cgviewSourceId}.`;
      const response = tool.handleAddConfirmed({cgv: this.context.cgv, mapData, inputs, jobSourceID, jobID, job});
      if (response.ok) {
        const tabs = this.context.tabsRef;
        tabs.setTabByID('map');
        this.setState({openDialog: false, mapData: undefined});
        Toast.create('Results added to the map', 1000);
        onClose();
      } else {
        dialog.showErrors([response.error]);
      }
    }
  }

  render() {
    const { job, type, text, width } = this.props;
    const { openDialog, mapData } = this.state;

    // console.log('Job:', job)
    // console.log(`mapData: ${mapData}`)

    let activeJobAddDialog;
    if (openDialog) {
      activeJobAddDialog = Tools.get(job.tool.publicID).addDialog(this.onAddToMapClosed, mapData, job);
    }

    const addButton = job.canAddResults && (
      <ImageButton
        imageName='add'
        text={text}
        width={width}
        size='default'
        type={type}
        title='Add Results to Map'
        onClick={ () => this.onAddClicked() } / >
     );

    // We stop the propagation as this causes the job header to open/close when clicked
    return (
      <div className='group-me' style={{width, display: 'inline-block'}} onClick={(e) => {e.stopPropagation()}} >
        {activeJobAddDialog}
        {addButton}
      </div>
    )
  }

}

AddJobResultsButton.contextType = CGViewContext;

//Connected
const addJobResultsButtonMapStateToProps = (state, ownProps) => ({ job: state.jobs.byID[ownProps.id] });
const ConnectedAddJobResultsButton = connect(addJobResultsButtonMapStateToProps, null, null, {forwardRef: true})(AddJobResultsButton);

// export default LogCard;
export { AddJobResultsButton, ConnectedAddJobResultsButton};
