// NOTES:
// - A DataElement is a single element (e.g text input, select, swtich, etc) with a label and optional help
// - A DataElementGroup is a row of of DataElements, filled to take the available space (by width)
// - A DataElementContainer contains one or more DataElements and/or DataElementGroups

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import HelpElement from './HelpElement';
import './DataElementGroup.css';

DataElementGroup.propTypes = {
  className: PropTypes.string,
  label:     PropTypes.node,
  help:      PropTypes.string,
  helpMore:  PropTypes.node,
  children:  PropTypes.node.isRequired,
  disabled:  PropTypes.bool,
}

DataElementGroup.defaultProps = {
  disabled: false,
  help: '',
}

function DataElementGroup(props) {
  const {
    className,
    label,
    children,
    help,
    helpMore,
    // disabled=false,
  } = props;

  const elementClass = classNames(
    'DataElementGroup', className
  );

  // const renderHelp = help ? <div className='help-text'>{help}</div> : '';
  // const helpMore = null;
      // {renderHelp}

  return (
    <div className={elementClass}>
      <div className='label'>{label}</div>
      <div className='children-group'>
        {children}
      </div>
      <HelpElement text={help} moreText={helpMore} />
    </div>
  )
}

export default DataElementGroup;

