import React from 'react';
import DataCard from '../../../cards/DataCard';
import { VirtualGrid, Column } from '../../../presenters/VirtualGrid';
// import classNames from 'classnames';
import './PhastestSummaryDataCard.css';

class PhastestDetailsDataCard extends DataCard {

  get extractType() {
    return 'json';
  }

  renderData(detailsJSON) {
    console.log(detailsJSON);

    const header = ['Region', 'Location', 'Class', 'E-Value', 'Name'];
    const data = [];
    detailsJSON.forEach((d, i) => {
      data.push([
        d.region_index,
        `${d.start} - ${d.stop}`,
        d.phage_bac_class,
        d['e-value'],
        d.name,
      ]);
    });

    if (data.length === 0) {
      return (
        <div className='phastest-help phastest-summary-found'>
            No Phage Regions Found
        </div>
      );
    } else {
      return (
        <VirtualGrid
          data={data}
          header={header}
          alternateRowColors
          rowHeight={30}
          defaultColumnWidth={80}
        >
          <Column index={0} width={80} type='number' />
          <Column index={1} width={170} />
          <Column index={2} width={80} />
          <Column index={3} width={120} type='number' />
          <Column index={4} width={700} linkerProps={{
            delimiter: ';', extractRegex: /^.*(NC_\d+)$/,
            url: 'https://www.ncbi.nlm.nih.gov/nuccore/{VALUE}'
          }} />
        </VirtualGrid>
      );
    }
  }
}

export default PhastestDetailsDataCard;
