// This file was automatically generated by the rake task tools:update:imports.
// Date: 2025-03-20 07:55:32 -0600
// TODO: add more helpful info...

import plots_add from '../tools/plots/add';
import plots_DialogAdd from '../tools/plots/components/DialogAdd';
import plannotate_DialogStart from '../tools/plannotate/components/DialogStart';
import plannotate_ReportCard from '../tools/plannotate/components/ReportCard';
import plannotate_FeaturesDataCard from '../tools/plannotate/components/FeaturesDataCard';
import plannotate_MapDataCard from '../tools/plannotate/components/MapDataCard';
import gc_skew_add from '../tools/gc_skew/add';
import gc_skew_DialogAdd from '../tools/gc_skew/components/DialogAdd';
import alien_hunter_DialogStart from '../tools/alien_hunter/components/DialogStart';
import alien_hunter_DialogAdd from '../tools/alien_hunter/components/DialogAdd';
import alien_hunter_ReportCard from '../tools/alien_hunter/components/ReportCard';
import alien_hunter_HGTRegionsCard from '../tools/alien_hunter/components/HGTRegionsCard';
import bakta_DialogStart from '../tools/bakta/components/DialogStart';
import bakta_ReportCard from '../tools/bakta/components/ReportCard';
import bakta_BaktaDataCard from '../tools/bakta/components/BaktaDataCard';
import gc_content_add from '../tools/gc_content/add';
import gc_content_DialogAdd from '../tools/gc_content/components/DialogAdd';
import orfs_add from '../tools/orfs/add';
import assemble_ReportCard from '../tools/assemble/components/ReportCard';
import assemble_QuastCard from '../tools/assemble/components/QuastCard';
import assemble_IcarusCard from '../tools/assemble/components/IcarusCard';
import prokka_DialogStart from '../tools/prokka/components/DialogStart';
import prokka_ReportCard from '../tools/prokka/components/ReportCard';
import prokka_ProkkaDataCard from '../tools/prokka/components/ProkkaDataCard';
import cgview_builder_add from '../tools/cgview_builder/add';
import cgview_builder_ReportCard from '../tools/cgview_builder/components/ReportCard';
import fastani_DialogStart from '../tools/fastani/components/DialogStart';
import fastani_ReportCard from '../tools/fastani/components/ReportCard';
import phastest_add from '../tools/phastest/add';
import phastest_DialogStart from '../tools/phastest/components/DialogStart';
import phastest_DialogAdd from '../tools/phastest/components/DialogAdd';
import phastest_ReportCard from '../tools/phastest/components/ReportCard';
import phastest_PhastestSummaryDataCard from '../tools/phastest/components/PhastestSummaryDataCard';
import phastest_PhastestDetailsDataCard from '../tools/phastest/components/PhastestDetailsDataCard';
import blast_formatter_add from '../tools/blast_formatter/add';
import blast_formatter_DialogAdd from '../tools/blast_formatter/components/DialogAdd';
import mitos_DialogStart from '../tools/mitos/components/DialogStart';
import mitos_ReportCard from '../tools/mitos/components/ReportCard';
import mitos_MitosDataCard from '../tools/mitos/components/MitosDataCard';
import features_add from '../tools/features/add';
import features_DialogAdd from '../tools/features/components/DialogAdd';
import features_FeatureHelp from '../tools/features/components/FeatureHelp';
import eggnog_EggNogDataCard from '../tools/eggnog/components/EggNogDataCard';
import card_ReportCard from '../tools/card/components/ReportCard';
import card_CARDDataCard from '../tools/card/components/CARDDataCard';
import blast_add from '../tools/blast/add';
import blast_DialogStart from '../tools/blast/components/DialogStart';
import blast_DialogAdd from '../tools/blast/components/DialogAdd';
import blast_ReportCard from '../tools/blast/components/ReportCard';
import blast_BlastDataCard from '../tools/blast/components/BlastDataCard';
import mobile_og_db_add from '../tools/mobile_og_db/add';
import mobile_og_db_DialogStart from '../tools/mobile_og_db/components/DialogStart';
import mobile_og_db_DialogAdd from '../tools/mobile_og_db/components/DialogAdd';
import mobile_og_db_ReportCard from '../tools/mobile_og_db/components/ReportCard';
import mobile_og_db_AlignmentsCard from '../tools/mobile_og_db/components/AlignmentsCard';
import track_lister_add from '../tools/track_lister/add';
import track_lister_DialogAdd from '../tools/track_lister/components/DialogAdd';
import virsorter_ReportCard from '../tools/virsorter/components/ReportCard';
import virsorter_ScoreCard from '../tools/virsorter/components/ScoreCard';
import crispr_cas_finder_add from '../tools/crispr_cas_finder/add';
import crispr_cas_finder_DialogAdd from '../tools/crispr_cas_finder/components/DialogAdd';
import crispr_cas_finder_ReportCard from '../tools/crispr_cas_finder/components/ReportCard';
import crispr_cas_finder_HTMLDataCard from '../tools/crispr_cas_finder/components/HTMLDataCard';
import phigaro_add from '../tools/phigaro/add';
import phigaro_DialogStart from '../tools/phigaro/components/DialogStart';
import phigaro_DialogAdd from '../tools/phigaro/components/DialogAdd';
import phigaro_ReportCard from '../tools/phigaro/components/ReportCard';
import phigaro_PhigaroCard from '../tools/phigaro/components/PhigaroCard';

const ToolComponents = {
  "plots": {
    "add": plots_add,
    "DialogAdd": plots_DialogAdd
  },
  "plannotate": {
    "DialogStart": plannotate_DialogStart,
    "ReportCard": plannotate_ReportCard,
    "FeaturesDataCard": plannotate_FeaturesDataCard,
    "MapDataCard": plannotate_MapDataCard
  },
  "gc_skew": {
    "add": gc_skew_add,
    "DialogAdd": gc_skew_DialogAdd
  },
  "alien_hunter": {
    "DialogStart": alien_hunter_DialogStart,
    "DialogAdd": alien_hunter_DialogAdd,
    "ReportCard": alien_hunter_ReportCard,
    "HGTRegionsCard": alien_hunter_HGTRegionsCard
  },
  "bakta": {
    "DialogStart": bakta_DialogStart,
    "ReportCard": bakta_ReportCard,
    "BaktaDataCard": bakta_BaktaDataCard
  },
  "gc_content": {
    "add": gc_content_add,
    "DialogAdd": gc_content_DialogAdd
  },
  "orfs": {
    "add": orfs_add
  },
  "assemble": {
    "ReportCard": assemble_ReportCard,
    "QuastCard": assemble_QuastCard,
    "IcarusCard": assemble_IcarusCard
  },
  "prokka": {
    "DialogStart": prokka_DialogStart,
    "ReportCard": prokka_ReportCard,
    "ProkkaDataCard": prokka_ProkkaDataCard
  },
  "cgview_builder": {
    "add": cgview_builder_add,
    "ReportCard": cgview_builder_ReportCard
  },
  "fastani": {
    "DialogStart": fastani_DialogStart,
    "ReportCard": fastani_ReportCard
  },
  "phastest": {
    "add": phastest_add,
    "DialogStart": phastest_DialogStart,
    "DialogAdd": phastest_DialogAdd,
    "ReportCard": phastest_ReportCard,
    "PhastestSummaryDataCard": phastest_PhastestSummaryDataCard,
    "PhastestDetailsDataCard": phastest_PhastestDetailsDataCard
  },
  "blast_formatter": {
    "add": blast_formatter_add,
    "DialogAdd": blast_formatter_DialogAdd
  },
  "mitos": {
    "DialogStart": mitos_DialogStart,
    "ReportCard": mitos_ReportCard,
    "MitosDataCard": mitos_MitosDataCard
  },
  "features": {
    "add": features_add,
    "DialogAdd": features_DialogAdd,
    "FeatureHelp": features_FeatureHelp
  },
  "eggnog": {
    "EggNogDataCard": eggnog_EggNogDataCard
  },
  "card": {
    "ReportCard": card_ReportCard,
    "CARDDataCard": card_CARDDataCard
  },
  "blast": {
    "add": blast_add,
    "DialogStart": blast_DialogStart,
    "DialogAdd": blast_DialogAdd,
    "ReportCard": blast_ReportCard,
    "BlastDataCard": blast_BlastDataCard
  },
  "mobile_og_db": {
    "add": mobile_og_db_add,
    "DialogStart": mobile_og_db_DialogStart,
    "DialogAdd": mobile_og_db_DialogAdd,
    "ReportCard": mobile_og_db_ReportCard,
    "AlignmentsCard": mobile_og_db_AlignmentsCard
  },
  "track_lister": {
    "add": track_lister_add,
    "DialogAdd": track_lister_DialogAdd
  },
  "virsorter": {
    "ReportCard": virsorter_ReportCard,
    "ScoreCard": virsorter_ScoreCard
  },
  "crispr_cas_finder": {
    "add": crispr_cas_finder_add,
    "DialogAdd": crispr_cas_finder_DialogAdd,
    "ReportCard": crispr_cas_finder_ReportCard,
    "HTMLDataCard": crispr_cas_finder_HTMLDataCard
  },
  "phigaro": {
    "add": phigaro_add,
    "DialogStart": phigaro_DialogStart,
    "DialogAdd": phigaro_DialogAdd,
    "ReportCard": phigaro_ReportCard,
    "PhigaroCard": phigaro_PhigaroCard
  }
};

export default ToolComponents;
