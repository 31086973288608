import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import DataElement from '../presenters/DataElement';
import DataElementGroup from '../presenters/DataElementGroup';
import TextInput from '../presenters/TextInput';
import { Select, Option } from '../presenters/Select';
import * as helpers from '../support/Helpers';
// import './SelectLegend.css';

class SelectWithNew extends Component {

  static propTypes = {
    items: PropTypes.shape({
      ids:  PropTypes.array,
      byID: PropTypes.object,
    }).isRequired,
    // title: PropTypes.string,
    title: PropTypes.node,
    defaultItemID: PropTypes.string,
    newItemLabel: PropTypes.string,
    allowNewItem: PropTypes.bool,
    // If true, the renderGroupElement method will be called to render a group element
    // Besides the select element much like the New Item element
    // The group element is empty by default and should be overridden
    allowGroupItems: PropTypes.bool,
    defaultNewItemName: PropTypes.string,
    additionalItems: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    help: PropTypes.string,
    helpPath:  PropTypes.string,
    onChange: PropTypes.func,
    // This value will cause an initial call to onChange in the constructor
    // This is required when the initial value will need to be sent to onChange
    // such as in DefaultDialogs.
    callInitialOnChange: PropTypes.bool,
    nonDefault: PropTypes.bool,
  }

  static defaultProps = {
    title: 'Select',
    allowNewItem: true,
    allowGroupItems: false,
    defaultNewItemName: 'New Item',
    defaultItemID: 'NEW',
    newItemLabel: 'Item',
    additionalItems: [],
    // help: 'Choose or create a legend.',
    onChange: () => {},
    nonDefault: false,
  }

  constructor(props) {
    super(props);
    const { allowNewItem, defaultItemID, defaultNewItemName } = props;
    const items = helpers.itemsAsArray(props.items);
    const selectedItemID = ( allowNewItem || (defaultItemID !== 'NEW') ) ? defaultItemID : (items[0] && items[0].cgvID);
    this.state = {
      newItemName: props.defaultNewItemName,
      selectedItemID,
    }
    if (props.callInitialOnChange) {
      this.returnCurrentItem();
    }
  }

  returnCurrentItem() {
    const { selectedItemID, newItemName } = this.state;
    this.props.onChange({cgvID: selectedItemID, name: newItemName});
  }

  onChange({value, attribute}) {
    if (attribute === 'selectedItemID' && value === 'NEW') {
      setTimeout( () => {this.newInputRef && this.newInputRef.focus()})
    }
    this.setState((state) => ({
      ...state.options,
      [attribute]: value,
    }),
      this.returnCurrentItem
    );
  }

  addAdditionalItems(options) {
    const { additionalItems } = this.props;
    const itemsObject = helpers.convertToObject(additionalItems);
    for (const key of Object.keys(itemsObject)) {
      options.unshift(<Option key={key} value={key}>{itemsObject[key]}</Option>);
    }
  }

  renderNewItem() {
    const { allowNewItem, newItemLabel, defaultNewItemName } = this.props;
    const { newItemName, selectedItemID } = this.state;
    // FIXME: if they empty the New text input, it goes back to the defaultNewItemName
    const name = newItemName || defaultNewItemName;
    if (allowNewItem && selectedItemID === 'NEW') {
      return (
        <DataElement label={`New ${newItemLabel} Name`}>
          <TextInput
            inputRef={ (ref) => {this.newInputRef = ref} }
            value={name}
            onChange={(value) => this.onChange({value, attribute: 'newItemName'})} />
        </DataElement>
      );
    } else {
      return <DataElement />;
    }
  }

  // Override as needed
  renderGroupElement() {
  }

  renderOptions() {
    const { allowNewItem, newItemLabel } = this.props;
    const items = helpers.itemsAsArray(this.props.items);
    const options = items.map( i => <Option key={i.cgvID} value={i.cgvID}>{i.name}</Option> );
    this.addAdditionalItems(options);
    if (allowNewItem) {
      options.unshift(<Option key='NEW' value='NEW'>New {newItemLabel}</Option>)
    }
    return options
  }

  renderSelect() {
    const { selectedItemID } = this.state;
    return (
      <Select value={selectedItemID} onChange={(value, option) => this.onChange({value: option.key, attribute: 'selectedItemID'})}>
        {this.renderOptions()}
      </Select>
    )
  }

  renderHelpMore() {
    return this.props.helpMore;
  }

  renderAsGroup() {
    const { title, help, helpPath, allowGroupItems, nonDefault } = this.props;
    const helpMore = this.renderHelpMore();
    return (
      <DataElementGroup help={help} helpMore={helpMore}>
        <DataElement label={title} helpPath={helpPath} nonDefault={nonDefault}>
          {this.renderSelect()}
        </DataElement>
        {this.renderNewItem()}
        {allowGroupItems && this.renderGroupElement()}
      </DataElementGroup>
    );
  }

  renderAsElement() {
    const { title, help, helpPath } = this.props;
    return (
      <DataElement label={title} help={help} helpPath={helpPath}>
        {this.renderSelect()}
      </DataElement>
    );
  }

  render() {
    const { allowNewItem, allowGroupItems } = this.props;
    // console.log(allowNewItem, allowGroupItems)
    const renderGroupOrElement = (allowNewItem || allowGroupItems)  ? this.renderAsGroup() : this.renderAsElement();
    return (
      <div className='SelectNew'>
        {renderGroupOrElement}
      </div>
    );
  }

}
// <div className='SelectNew'>
//        <DataElementGroup help={help}>
//          <DataElement label={title}>
//            <Select value={selectedItemID} onChange={(value, option) => this.onChange({value: option.key, attribute: 'selectedItemID'})}>
//              {this.renderOptions()}
//            </Select>
//          </DataElement>
//          {this.renderNewItem()}
//        </DataElementGroup>
//      </div>
//
export default SelectWithNew;

