import React from 'react';
import ReactDOM from 'react-dom';
import './MyProjects.css';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ServerAPI from '../proksee-viewer/models/ServerAPI';
import ProjectTable from './ProjectTable';
import CookieSwitch from './CookieSwitch';
import * as helpers from "../proksee-viewer/support/Helpers";

class MyProjects extends React.Component {

  static propTypes = {
    user:   PropTypes.object,
    daysToKeep: PropTypes.number,
  }

  constructor(props) {
    super(props);
    // If sending list as a get request we should keep the URL size below 2000 characters
    // Each ID is 36 characters. Realistically this should be an issue.
    // Who would keep 50 session projects that they have to update every week? Famous last words!
    // NIY
    // this.sessionListLimit = 50;
    this.state = {
      userProjects: [],
      sessionProjects: [],
      moreCookieInfoOpen: false,
    }
  }

  componentDidMount() {
    this.refreshProjects();
  }

  refreshProjects() {
    const Server = new ServerAPI();

    const storage = window.localStorage;
    const sessionProjectsString = storage.getItem('session_project_ids');

    Server.get(Server.URL.projectList, {session_project_ids: sessionProjectsString})
    .then( response => {
      if (response.ok) {
        console.log(response.json)
        this.setState({
          userProjects: response.json['userProjects'],
          sessionProjects: response.json['sessionProjects'],
        });
        // Update LocalStorage
        const ids = response.json['sessionProjects'].map( p => p.uuid );
        if (CookieSwitch.allowCookies) {
          storage.setItem('session_project_ids', ids)
        }
      }
    });
  }

  onClickMoreCookieInfo() {
    this.setState((state) => ({moreCookieInfoOpen: !state.moreCookieInfoOpen}));
    console.log('asfdasdff')
  }

  renderSessionProjects() {
    const { user, daysToKeep } = this.props;
    const { sessionProjects } = this.state;
    const sectionClass = user ? 'projects-section' : 'projects-section-full';
    const claimOrLogin = user ?
      <div>Click "Claim" to add them to your account.</div> :
      <div><a href='users/sign_in'>Log in</a> and you can add these projects to your account.</div>

    const allowCookies = CookieSwitch.allowCookies;

    if (allowCookies && (!user || sessionProjects.length > 0)) {
      // Cookies Allowed
      return (
        <div className={sectionClass}>
          <div className='ps-alert ps-alert-notice session-project-notice'>
            Session projects are projects that have been created with this web browser while not logged in.
            {claimOrLogin}
            <div>
              <strong>Session projects will be deleted {daysToKeep} days after the <em>Last Save Date</em>.</strong>
            </div>
          </div>
          <div className='projects-session-table'>
            <ProjectTable title='Session Projects' projects={sessionProjects} sessionProjects user={user} />
          </div>
        </div>
      );
    } else if (!user && !allowCookies) {
      // Cookies NOT Allowed
      return (
        <div className='central-notice-wrapper'>
          <div className='ps-alert ps-alert-notice central-notice'>
            <div><strong>Cookies are turned off!</strong></div>
            { this.renderCookiesNotAllowedMessage() }
          </div>
        </div>
      );
    }
  }

  renderUserProjects() {
    const { user } = this.props;
    const { userProjects, sessionProjects, moreCookieInfoOpen } = this.state;

    const allowCookies = CookieSwitch.allowCookies;
    const userTableClass = classNames(
      {
        'projects-user-table-no-cookies': !allowCookies,
      }
    );

    const sessionProjectCookies = allowCookies ? null : (
      <div className={`ps-alert ps-alert-notice cookies-off-notice ${moreCookieInfoOpen && 'more'}`}>
        <div>
          Cookies off: unable to show session projects. 
          <a href='#' onClick={() => this.onClickMoreCookieInfo()}>{moreCookieInfoOpen && ' Less...' || ' More...'}</a>
        </div>
        {moreCookieInfoOpen && this.renderCookiesNotAllowedMessage()}
      </div>
    );

    if (user) {
      const sectionClass = sessionProjects.length ? 'projects-section' : 'projects-section-full';
      return (
        <div className={sectionClass}>
          {sessionProjectCookies}
          <div className={userTableClass}>
            <ProjectTable title='User Projects' projects={userProjects} />
          </div>
        </div>
      )
    }
  }

  // renderUserProjects() {
  //   const { user } = this.props;
  //   const { userProjects, sessionProjects } = this.state;
  //   const sessionProjectCookies = CookieSwitch.allowCookies ? null : (
  //     <div className='ps-alert ps-alert-notice'><div>Cookies off: not able to save session projects.</div></div>
  //   );
  //   //<div className='ps-alert ps-alert-notice'><div>Remembering session projects requires functional cookies to be turned on (they are currently off).</div><div>To see future session projects turn on functional cookies on the <a href='/privacy'>Privacy Policy</a> page.</div><div>Note, that session projects are projects that have been created with this web browser while not logged in.</div></div>
  //   console.log(sessionProjectCookies)
  //   //<div className='projects-session-table'>
  //   if (user) {
  //     const tableClass = sessionProjects.length ? 'projects-section' : 'projects-section-full';
  //     return (
  //       <div className={tableClass}>
  //         {sessionProjectCookies}
  //         <ProjectTable title='User Projects' projects={userProjects} />
  //       </div>
  //     )
  //   }
  // }

  renderCookiesNotAllowedMessage() {
    return (
      <div>
        <div>Remembering session projects requires functional cookies to be turned on.</div>
        <div>To see future session projects, turn on functional cookies on the <a href='/privacy'>Privacy Policy</a> page.</div>
        <div>Note, session projects are projects that have been created with this web browser while not logged in.</div>
      </div>
    );
  }

  render() {
    return (
      <div className='fill-area'>
        {this.renderSessionProjects()}
        {this.renderUserProjects()}
      </div>
    );
  }

}
export default MyProjects;

